import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
const Component = ({ item, num, pageContext }) => {
  return (
    <div
      className={`section images ${item?.bgColor ? 'bg' : ''}`}
      style={{ backgroundColor: item?.bgColor ?? 'transparent' }}
    >
      <div className="container">
        {item?.blocks &&
          item.blocks.map((block, i) => {
            const order = (parseFloat(item?.position ?? 0) + i) % 2
            return (
              <div
                key={`image${num + i}`}
                className="row block justify-content-center justify-content-md-between align-items-center"
              >
                <div
                  className={`col-8 col-md-5 ${block.imageType} order-0 order-md-${order}`}
                >
                  {block?.image && (
                    <Img
                      fluid={block?.image?.localFile?.childImageSharp?.fluid}
                      className="item-image"
                      alt={block.image.altText}
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 text">
                  {block?.title && <h2>{parse(block.title)}</h2>}
                  {block?.text && <div>{parse(block.text)}</div>}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Component
